import { Svg_Behance, Svg_Drive, Svg_Site, Svg_Vimeo, Svg_Youtube } from 'services/SvgFile';

import './Input.css';

import { colorIcon } from 'fixedData';

export default function Comp_Input(props){
    return(
        <div className={ props.className == '' ? "Comp_Input space_div" : props.className === "all" ? "Comp_Input input_all" : "Comp_Input" }>
            <input type={ props.type } className={ props.className } onChange={ (e)=>{ props.index === "not" ? props.setValue(e.target.value) : props.setValue(props.input, props.index, e.target.value, props.index_one) } } value={ props.mask ? props.mask(props.value) : props.value } maxLength={ props.maxLength } placeholder={ props.placeholder ? props.placeholder : "digite aqui a informação..." } required={ props.required ? true : false } readOnly={ props.readOnly ? props.readOnly : false } />
            <div className="name">{ props.name }</div>

            {
                props.iconVideo ?
                    props.value == "" ? null :
                        <div className="" onClick={ ()=>{props.OpenFile(props.iconVideo, props.value) } }>
                            {
                                props.iconVideo === 'vimeo' ?
                                <Svg_Vimeo className="icons" color={ colorIcon } /> :

                                    props.iconVideo === 'youtube' || props.iconVideo === 'Others' ?
                                    <Svg_Youtube className="icons" color={ colorIcon } /> :

                                        props.iconVideo === 'Site' ?
                                        <Svg_Site className="icons" color={ colorIcon } /> :

                                            props.iconVideo === 'Drive' ?
                                            <Svg_Drive className="icons" color={ colorIcon } /> :

                                                props.iconVideo === 'Behance' ?
                                                <Svg_Behance className="icons" color={ colorIcon } /> : null

                            }
                        </div>
                : null
            }
        </div>
    )
}