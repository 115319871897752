
// Cookies folder project
// export const cookiesRegister = './';
// export const urlPhp = 'http://localhost/React_JS/dashboard_portfolio/dashboard/public/php/';

export const cookiesRegister = "https://newmodel.chroma-garden.com/";
export const urlPhp = "https://newmodel.chroma-garden.com/php/";

// Cookies name
// const nameProject = 'esc';
const nameProject = 'chroma_comercial';
export const typeCookie = nameProject + '_dashboard';
export const typeCookiePage = nameProject + '_page';
export const typeCookiePageId = nameProject + '_page_id';
export const typeCookiePassw = nameProject + '_passw';
export const typeCookieEmail = nameProject + '_email';

// name icons
export const colorIcon = '#B2B2B2';
export const colorIcon2 = '#666666';
export const colorIcon3 = '#ADFA06';

export const defaultColor = '#000000';
export const colorIconSecondary = '#ffc400';

// show opt email
export const selectTypeEmail = [
    { "value": "Updates de venda", "label": "Updates de venda" }
];

// table columns - client
export const tableColumnsClient = [
    { "id": 0, "origin": "client", "order": 0, "statusTd": true, "value": "name", "label": "Cliente" },
    { "id": 0, "origin": "client", "order": 1, "statusTd": true, "value": "cnpj", "label": "CNPJ" },
    { "id": 0, "origin": "client", "order": 2, "statusTd": true, "value": "corporate_name", "label": "Razão Social" },
    { "id": 0, "origin": "client", "order": 3, "statusTd": true, "value": "acting_area", "label": "Área de atuação" },
    { "id": 0, "origin": "client", "order": 4, "statusTd": true, "value": "customer_since", "label": "Cliente desde" },
    { "id": 0, "origin": "client", "order": 5, "statusTd": true, "value": "end_budget", "label": "Último orçamento" }
];

// table columns - budget
export const tableColumnsBudget = [
    { "id": 0, "origin": "budget", "order": 0, "statusTd": true, "value": "code", "label": "Código" },
    { "id": 0, "origin": "budget", "order": 1, "statusTd": true, "value": "status", "label": "Status" },
    { "id": 0, "origin": "budget", "order": 2, "statusTd": true, "value": "motive", "label": "Motivo" },
    { "id": 0, "origin": "budget", "order": 3, "statusTd": true, "value": "client", "label": "Cliente" },
    { "id": 0, "origin": "budget", "order": 4, "statusTd": true, "value": "subject", "label": "Assunto" },
    { "id": 0, "origin": "budget", "order": 5, "statusTd": true, "value": "date_create", "label": "Data registrada" },
    { "id": 0, "origin": "budget", "order": 6, "statusTd": true, "value": "date_start", "label": "Início evento" },
    { "id": 0, "origin": "budget", "order": 7, "statusTd": true, "value": "date_end", "label": "Término evento" },
    { "id": 0, "origin": "budget", "order": 8, "statusTd": true, "value": "signature", "label": "Assinatura" },
    { "id": 0, "origin": "budget", "order": 9, "statusTd": true, "value": "price", "label": "Valor R$" },
    { "id": 0, "origin": "budget", "order": 10, "statusTd": true, "value": "icon_notification", "label": "Ícone de notificação" }
];

// opt page
export const optPage = [
    { "id": 0, "order_": 1, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Configurações", "page": "config" },
    { "id": 0, "order_": 2, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Clientes", "page": "client" },
    { "id": 0, "order_": 3, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Portfólio", "page": "portfolio" },
    { "id": 0, "order_": 4, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Serviços", "page": "services" },
    { "id": 0, "order_": 5, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Orçamentos", "page": "budgets" },
    { "id": 0, "order_": 6, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Relatórios", "page": "reports" },
    { "id": 0, "order_": 7, "type": { "value": "hide_page", "label": "Sem acesso" }, "name": "Tarefas", "page": "tasks" }
];

// opt layout services
export const optLayout = [
    { "value": "img", "label": "Imagem" },
    { "value": "vimeo", "label": "Vimeo" },
    { "value": "youtube", "label": "Youtube" }
]

// opt layout portfolio
export const optPortfolio = [
    { "value": "img", "label": "Imagem" },
    { "value": "color", "label": "Cor" },
    { "value": "vimeo", "label": "Vimeo" },
    { "value": "youtube", "label": "Youtube" }
]

// opt links
export const optLink = [
    { "value": "Site", "label": "Site" },
    { "value": "Drive", "label": "Drive" },
    { "value": "Outros", "label": "Outros" },
    { "value": "Behance", "label": "Behance" }
]

// opt tag
export const optTag = [
    { "value": "Público", "label": "Público" },
    { "value": "Privado", "label": "Privado" }
]

// alt format price
export function FormatPrice(type, value){
    if (typeof value === 'string') {
        if (type === 'en-US') {
            value = parseFloat(value.replace('.', '').replace(',', '.'));
        } else if (type === 'pt-BR') {
            value = parseFloat(value.replace(',', '.'));
        }
    }

    if (value > 0) {
        switch (type) {
            case 'pt-BR':
                return value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            case 'en-US':
                return value;
        }
    } else {
        switch (type) {
            case 'pt-BR':
                return "0,00";
            case 'en-US':
                return 0;
        }
    }
}

// show price br
export function ShowPriceBr(value){
    let currentValue = value.replace('[0-9]+$');
    let removeCommaDot = Number(currentValue.replace(/[.,]/g, ''));
    removeCommaDot = parseFloat(removeCommaDot * 0.01).toFixed(2);

    let newValue = removeCommaDot.toString();
    newValue = newValue.replace('.', ',');
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    return newValue;
}

// opt box editor
export const config = {
    zIndex: 0,
    readonly: false,
    width: 'auto',
    height: 'auto',
    minHeight: 100,
    direction: '',
    language: 'auto',
    tabIndex: -1,
    buttons: ['bold', 'italic', 'underline', 'align'],
    link: {
        modeClassName: false,
        noFollowCheckbox: false,
        openInNewTabCheckbox: false,
        processPastedLink: false,
        processVideoLink: false,
        selectMultipleClassName: false,
        selectOptionsClassName: false,
    },
    imageDefaultWidth: 24,
    image: {
        dialogWidth: true,
        editAlign: false,
        editAlt: false,
        editBorderRadius: true,
        editClass: false,
        editId: false,
        editLink: true,
        editMargins: false,
        editSize: true,
        editSrc: true,
        editStyle: false,
        editTitle: false,
        openOnDblClick: true,
        selectImageAfterClose: true,
        showPreview: true,
        useImageEditor: true,
    },
    placeholder: "digite aqui a informação...",
    toolbarAdaptive: false
}