
let DataPage = {
    "upload_site": false,
    "settings": {},
    "client": [],
    "portfolio": [],
    "portfolio_tag": [],
    "services": [],
    "services_category": [],
    "services_combo": [],
    "access_dash": []
};

let NotifyDataPage = {
    "upload_site": [],
    "settings": [],
    "client": [],
    "portfolio": [],
    "portfolio_tag": [],
    "services": [],
    "services_category": [],
    "services_combo": [],
    "access_dash": []
}

export function SetListData(value) {
    DataPage = value;
    NotifyDataPage["upload_site"].forEach(element => { element(value['upload_site']); });
    NotifyDataPage["settings"].forEach(element => { element(value['settings']); });
    NotifyDataPage["client"].forEach(element => { element(value['client']); });
    NotifyDataPage["portfolio"].forEach(element => { element(value['portfolio']); });
    NotifyDataPage["portfolio_tag"].forEach(element => { element(value['portfolio_tag']); });
    NotifyDataPage["services"].forEach(element => { element(value['services']); });
    NotifyDataPage["services_category"].forEach(element => { element(value['services_category']); });
    NotifyDataPage["services_combo"].forEach(element => { element(value['services_combo']); });
    NotifyDataPage["access_dash"].forEach(element => { element(value['access_dash']); });
}

export function SetListDataSingle(key, value) {
    DataPage[key] = value;
    NotifyDataPage[key].forEach(element => {
        element(value);
    });
}

export function GetDataPage(key){
    return DataPage[key];
}

export function RegisterDataPage(key, value){
    if(!NotifyDataPage[key]){
        NotifyDataPage[key] = [];
    }
    NotifyDataPage[key].push(value);
}

export function UnRegisterDataPage(key, callback){
    if(NotifyDataPage[key].length > 0){
        NotifyDataPage[key] = NotifyDataPage[key].filter((item) => {
            return item !== callback;
        });
    }
}