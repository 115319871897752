import React, { useState, useEffect } from "react";

import './Category.css';

import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import { GetDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { defaultColor, colorIcon3, optLayout } from "fixedData";

import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_InputFile from "components/InputFile";

import { Svg_Close } from "services/SvgFile";
import { Reg_ServicesCategory } from "services/Register";

export default function PopUP_Category(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ type, setType ] = useState({ "value": "img", "label": "Imagem" });
    const [ name, setName ] = useState('');
    const [ thumbnail, setThumbnail ] = useState('');
    const [ file, setFile ] = useState('');
    const [ fileStatus, setFileStatus ] = useState(false);
    const [ link, setLink ] = useState('');
    const [ color, setColor ] = useState('');

    function InitialData(type){
        const newData = GetDataPage('services_category').find(item => item.id == modalData.id);
        if(newData){
            return newData[type]
        }
        if(type === 'type'){
            return { "value": "img", "label": "Imagem" };
        }
        return '';
    }

    function ClosePopUp(){
        setType({ "value": "img", "label": "Imagem" });
        setName('');
        setThumbnail('');
        setFile('');
        setFileStatus(false);
        setLink('');
        setColor('');
        SetModalState('Category', false);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_ServicesCategory(props.userId, modalData.id, type['value'], name, file, link, color, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterModalData('Category', setModaldata);
        RegisterModalObserver('Category', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setType(InitialData('type'));
            setName(InitialData('name'));
            setThumbnail(InitialData('thumbnail'));
            setFile(InitialData('file'));
            setFileStatus(false);
            setLink(InitialData('link'));
            setColor(InitialData('color'));
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp PopUpCenter" onSubmit={ SaveData }>
                <div className="all Category">
                    <div className="div_data type_div">
                        <div className="title">Categoria</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ defaultColor } className="icons" />
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="content">
                            <div className="div_input">
                                <Comp_Select index="not" name="Tipo de capa*" className="type_data" opt={ optLayout } setValue={ setType } value={ type } input="type" />

                                {
                                    type['value'] === 'img' ?
                                        <Comp_InputFile index="not" setValue={ setFile } value={ file } setStatus={ setFileStatus } status={ fileStatus } OpenFile={ props.OpenFile } thumbnail={ fileStatus ? file : thumbnail } title1="Adicionar foto" title2="Foto adicionado" />
                                    : null
                                }
                            </div>

                            <div className="div_input">
                                <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome da categoria*" />
                                
                                <div className="div_color">
                                    <div className="legend">Cor do texto</div>                                   
                                    <div className="color" style={ { background: color } } onClick={ ()=>{ props.SetColor(setColor); } }  data-tooltip-id="show_alert_color" data-tooltip-content="Clique aqui para alterar a cor" data-tooltip-place="top" />
                                </div>
                            </div>

                            {
                                type['value'] === 'img' ? null :
                                <div className="div_input">
                                    <Comp_Input className="" type="text" index="not" setValue={ setLink } value={ link } input="" maxLength={ 140 } placeholder="" required={ true } name="Link do vídeo*" iconVideo={ type['value'] } OpenFile={ props.OpenFile } />
                                </div>
                            }

                            <Tooltip id="show_alert_color" />

                            <div className="div_btn">
                                <button type="submit" style={ { background: colorIcon3 } }>
                                    {
                                        modalData.id === 0 ? "Cadastrar" : "Salvar"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
