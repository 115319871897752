import Axios from 'axios';

import Cookies from 'universal-cookie';

import { SetListPag } from 'interface/Page';
import { SetListDataSingle } from 'interface/Data';
import { GetUserData, SetUserData } from 'interface/Users';

import { cookiesRegister, typeCookieEmail, typeCookiePassw, urlPhp } from 'fixedData';

// Delete or alt status data
export function Reg_CheckData(name_table, name, SaveData, CallbackError){
    const data = new FormData();

    data.append('type_page', 'check_data');
    data.append('name_table', name_table);
    data.append('name', name);

    Axios({
        url : urlPhp + 'inc/check_data.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        
        console.log(response.data);        
        if(response.data['type'] === 'existing_data'){
            CallbackError(name_table, response.data['list'], SaveData);
        }else {
            SaveData();
        }

    }).catch((error)=>{
        console.log(error);        
    })
}

// Delete or alt status data
export function Reg_AltStatus(userId, origin, type, id, status, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('id_user', userId);
    data.append('type_page', origin);
    data.append('edit_data', type);
    data.append('id', id);
    data.append('status', status);

    Axios({
        url : urlPhp + 'page/' + origin + '.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        if(origin === 'services'){
            SetListDataSingle('services', response.data['services']);
            SetListDataSingle('services_combo', response.data['services_combo']);
            SetListDataSingle('services_category', response.data['services_category']);

        }else {
            SetListDataSingle(origin, response.data[origin]);
        }
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Recover pass
export function CheckedEmail(email, CallbackSuccess, CallbackError, CallbackError_Send, CallbackError_NotEmail){
    const data = new FormData();

    data.append('type_page', "recover_email");
    data.append('email', email);

    Axios({
        url : urlPhp + 'email/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch (response.data) {
            case "Email sent successfully": case "Connected": CallbackSuccess(); break;
            case "Error sending message": case "Error": CallbackError_Send(); break;
            case "Email not sent": case "Error": CallbackError_NotEmail(); break;
            case "Email not found": case "Error": CallbackError(); break;
            default: CallbackError(); break;
        }

    }).catch((error)=>{
        console.log(error);        
        CallbackError();
    })
}
// Checked pass or alt pass
export function CodeNewPass(type, pass, code, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', type);
    data.append('pass', pass);
    data.append('code', code);

    Axios({
        url : urlPhp + 'email/recover_pass.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {
        console.log(response.data);        
        switch (response.data) {
            case "Email successfully sent": case "Connected":
                    CallbackSuccess(); 
                break;
            default: 
                    CallbackError(); 
                break;
        }

    }).catch((error)=>{
        CallbackError();
    })
}


// Page - Settings
export function Reg_Settings(userId, listEmail, listAnnualGoal, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'settings');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    
    // list email
    listEmail.forEach((elem, index) => {
        data.append('email_id[]', elem.id);
        data.append('email_type[]', elem.type['value']);
        data.append('email_name[]', elem.name);
        data.append('email[]', elem.email);
    });

    // list annual goal
    listAnnualGoal.forEach((elem, index) => {
        data.append('annual_goal_id[]', elem.id);
        data.append('annual_goal_year[]', elem.year);
        data.append('annual_goal_obs[]', elem.obs);
        data.append('annual_goal[]', elem.annual_goal);
        data.append('annual_goal_january[]', elem.january);
        data.append('annual_goal_february[]', elem.february);
        data.append('annual_goal_march[]', elem.march);
        data.append('annual_goal_april[]', elem.april);
        data.append('annual_goal_may[]', elem.may);
        data.append('annual_goal_june[]', elem.june);
        data.append('annual_goal_july[]', elem.july);
        data.append('annual_goal_august[]', elem.august);
        data.append('annual_goal_september[]', elem.september);
        data.append('annual_goal_october[]', elem.october);
        data.append('annual_goal_november[]', elem.november);
        data.append('annual_goal_december[]', elem.december);
    });

    Axios({
        url : urlPhp + 'page/settings.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('settings', response.data.settings);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Page - Client
export function Reg_Client(userId, idPage, name, corporateName, cnpj, actingArea, customerSince, note, contact, file, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'client');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('name', name);
    data.append('corporate_name', corporateName);
    data.append('cnpj', cnpj);  
    data.append('acting_area', actingArea);
    data.append('customer_since', customerSince);
    data.append('note', note);  
    data.append('file', file);
    
    // list contact
    contact.forEach((elem, index) => {
        data.append('contact_id[]', elem.id);
        data.append('contact_name[]', elem.name);
        data.append('contact_email[]', elem.email);
        data.append('contact_phone[]', elem.phone);
        data.append('contact_office[]', elem.office);
        data.append('contact_file_' + index, elem.file);
    });

    Axios({
        url : urlPhp + 'page/client.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('client', response.data.client);
        SetListPag('currentPageId', response.data.id_page);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Page - Portfolio
export function Reg_Portfolio(userId, idPage, restricted, title, client, year, color, text, coverType, coverFile, coverColor, coverVideo, technicalSheet, listLinks, listLayout, listServices, listTags, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'portfolio');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('restricted', restricted['value']);
    data.append('title', title);
    data.append('client', client['value']);  
    data.append('year', year);
    data.append('color', color);
    data.append('text', text);  
    data.append('cover_type', coverType['value']);
    data.append('cover_file', coverFile);
    data.append('cover_color', coverColor);
    data.append('cover_video', coverVideo);
    data.append('technical_sheet', technicalSheet);
    
    // list Links
    listLinks.forEach((elem, index) => {
        data.append('link_id[]', elem.id);
        data.append('link_type[]', elem.type['value']);
        data.append('link_title[]', elem.title);
        data.append('link[]', elem.link);
    });
    
    // list Layout
    listLayout.forEach((elem, index) => {
        data.append('layout_id[]', elem.id);
        data.append('layout_index[]', index);
        data.append('layout_screen[]', elem.screen['value']);
        data.append('layout_type[]', elem.type);
        data.append('layout_qtd_layout[]', elem.layout.length);

        elem.layout.forEach((elem_1, index_1) => {
            data.append('layout_list_id[]', elem_1.id);
            data.append('layout_list_index[]', index);
            data.append('layout_list_type[]', elem_1.type['value']);
            data.append('layout_list_title[]', elem_1.title);
            data.append('layout_list_link[]', elem_1.link);
            data.append('layout_list_file_' + index + '_' + index_1, elem_1.file);
        });
    });
    
    // list Services
    listServices.forEach((elem, index) => {
        data.append('services_id[]', elem.id);
        data.append('services[]', elem.id_service['value']);
    });
    
    // list Tags
    listTags.forEach((elem, index) => {
        data.append('tags_id[]', elem.id);
        data.append('tags[]', elem.id_tag['value']);
    });

    Axios({
        url : urlPhp + 'page/portfolio.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('portfolio', response.data.portfolio);
        SetListPag('currentPageId', response.data.id_page);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Page - Portfolio - Tag
export function Reg_PortfolioTag(userId, idPage, type, name, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'portfolio');
    data.append('edit_data', 'edit_or_register_tag');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('type', type);
    data.append('name', name);

    Axios({
        url : urlPhp + 'page/portfolio.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('portfolio_tag', response.data.portfolio_tag);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}

// Page - Services
export function Reg_Services(userId, idPage, tag, name, price, percentage, text, listCategory, referralLink, presentationText, presentationLayout, presentation, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('tag', tag);
    data.append('name', name);
    data.append('price', price);  
    data.append('percentage', percentage);
    data.append('text', text);
    data.append('presentation_text', presentationText);  
    data.append('presentation_layout', presentationLayout);
    
    // list category
    listCategory.forEach((elem, index) => {
        data.append('category_id[]', elem.id);
        data.append('category_id_category[]', elem.id_category['value']);
    });
    
    // list referral link
    referralLink.forEach((elem, index) => {
        data.append('link_id[]', elem.id);
        data.append('link[]', elem.link);
    });
    
    // list presentation
    presentation.forEach((elem, index) => {
        data.append('presentation_id[]', elem.id);
        data.append('presentation_type[]', elem.type['value']);
        data.append('presentation_title[]', elem.title);
        data.append('presentation_file_' + index, elem.file);
        data.append('presentation_link[]', elem.link);
    });

    Axios({
        url : urlPhp + 'page/services.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('services', response.data.services);
        SetListPag('currentPageId', response.data.id_page);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Page - Services - Category
export function Reg_ServicesCategory(userId, idPage, type, name, file, link, color, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_or_register_category');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('type', type);
    data.append('name', name);
    data.append('file', file);  
    data.append('link', link);
    data.append('color', color);

    Axios({
        url : urlPhp + 'page/services.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('services_category', response.data.services_category);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Page - Services - Category - Color
export function Reg_ServicesCategoryColor(userId, idPage, color, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_or_register_category_color');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('color', color);

    Axios({
        url : urlPhp + 'page/services.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('services_category', response.data.services_category);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}
// Page - Services - Combo
export function Reg_ServicesCombo(userId, idPage, title, text, services, CallbackSuccess, CallbackError){
    const data = new FormData();

    data.append('type_page', 'services');
    data.append('edit_data', 'edit_or_register_combo');
    data.append('id_user', userId);
    data.append('id_page', idPage);
    data.append('title', title);
    data.append('text', text);

    services.forEach((elem, index)=>{
        data.append('services_id[]', elem.id);
        data.append('services[]', elem.id_service['value']);
    });

    Axios({
        url : urlPhp + 'page/services.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListPag('currentPageId', response.data.id_page);
        SetListDataSingle('services_combo', response.data.services_combo);
        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}


// Page - AccessDash
export function Reg_AccessDash(userId, id, name, email, file, password, access, note, page, columnsClient, columnsBudget, CallbackSuccess, CallbackError){
    const cookies = new Cookies();
    const data = new FormData();

    data.append('type_page', 'access_dash');
    data.append('edit_data', 'edit_or_register');
    data.append('id_user', userId);
    data.append('id', id);
    data.append('name', name);
    data.append('email', email);
    data.append('file', file);
    data.append('password', password);
    data.append('access', access['value']);
    data.append('note', note);

    // page
    page.forEach((elem, index) => {
        data.append('page_id[]', elem.id);
        data.append('page_order[]', elem.order_);
        data.append('page_type[]', elem.type['value']);
        data.append('page_name[]', elem.name);
        data.append('page_page[]', elem.page);
    });

    // columns client
    columnsClient.forEach((elem, index) => {
        data.append('columns_client_id[]', elem.id);
        data.append('columns_client_index[]', index);
        data.append('columns_client_status[]', elem.statusTd);
        data.append('columns_client_value[]', elem.value);
        data.append('columns_client_label[]', elem.label);
    });

    // columns budget
    columnsBudget.forEach((elem, index) => {
        data.append('columns_budget_id[]', elem.id);
        data.append('columns_budget_index[]', index);
        data.append('columns_budget_status[]', elem.statusTd);
        data.append('columns_budget_value[]', elem.value);
        data.append('columns_budget_label[]', elem.label);
    });

    Axios({
        url : urlPhp + 'page/access_dash.php',
        mode : 'no-cors',
        method : 'POST',
        data : data
    })
    .then(response => {

        console.log(response.data);
        SetListDataSingle('access_dash', response.data.access_dash);
        SetListPag('currentPageId', response.data.id_page);

        if(userId == id){
            // current date
            const day = new Date();

            // add 3 day
            const add3Days = new Date();
            add3Days.setDate(day.getDate() + 3);
    
            cookies.set(typeCookieEmail, response.data.email, { path: '/', expires: add3Days }, cookiesRegister);

            if(response.data.pass !=''){
                cookies.set(typeCookiePassw, response.data.pass, { path: '/', expires: add3Days }, cookiesRegister);
            }            

            SetUserData('file', response.data.file);
            SetUserData('name', response.data.name);
            SetUserData('email', response.data.email);
        }

        CallbackSuccess(); 

    }).catch((error)=>{
        CallbackError();
    })
}