import React, { useState, useEffect } from "react";

import './Tag.css';

import { GetDataPage } from "interface/Data";
import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { defaultColor, colorIcon3, optTag } from "fixedData";

import Comp_Input from "components/Input";
import Comp_Select from "components/Select";

import { Svg_Close } from "services/SvgFile";
import { Reg_PortfolioTag } from "services/Register";

export default function PopUP_Tag(props){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);

    const [ type, setType ] = useState({ "value": "Público", "label": "Público" });
    const [ name, setName ] = useState('');

    function InitialData(type){
        const newData = GetDataPage('portfolio_tag').find(item => item.id == modalData.id);
        if(newData){
            return newData[type];
        }
        if(type === 'type'){
            return { "value": "Público", "label": "Público" };
        }
        return '';
    }

    function ClosePopUp(){
        setType('');
        setName('');
        SetModalState('Tag', false);
    }

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_PortfolioTag(props.userId, modalData.id, type['value'], name, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterModalData('Tag', setModaldata);
        RegisterModalObserver('Tag', setShowPopUp);
    }, []);

    useEffect(()=>{
        if(showPopUp){
            setType(InitialData('type'));
            setName(InitialData('name'));
        }
    }, [showPopUp]);

    return (
        (showPopUp ?
            <form className="PopUp PopUpCenter" onSubmit={ SaveData }>
                <div className="all Tag">
                    <div className="div_data type_div">
                        <div className="title">Tag</div>
                        <div className="close" onClick={ ()=>{ ClosePopUp(); } }>
                            <Svg_Close color={ defaultColor } className="icons" />
                        </div>
                    </div>
                    <div className="div_data">
                        <div className="content">
                            <div className="div_input">
                                <Comp_Select index="not" name="Tipo de capa*" className="type_data" opt={ optTag } setValue={ setType } value={ type } input="type" />
                            </div>

                            <div className="div_input">
                                <Comp_Input className="" type="text" index="not" setValue={ setName } value={ name } input="" maxLength={ 140 } placeholder="" required={ true } name="Nome da tag*" />
                            </div>

                            <div className="div_btn">
                                <button type="submit" style={ { background: colorIcon3 } }>
                                    {
                                        modalData.id === 0 ? "Cadastrar" : "Salvar"
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="close_all" onClick={ ()=>{ ClosePopUp(); } } />
            </form>
        : <React.Fragment></React.Fragment>)
    );
}
