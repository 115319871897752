import { useState, useEffect } from "react";

import './Details.css';

import Comp_Topic from "components/Topic";
import Comp_Input from "components/Input";
import Comp_Select from "components/Select";
import Comp_Textarea from "components/Textarea";

import { GetListPag } from "interface/Page";
import { GetDataPage, RegisterDataPage, UnRegisterDataPage } from "interface/Data";

import { Reg_ServicesCombo } from "services/Register";
import { Svg_Delete } from "services/SvgFile";

import { colorIcon } from "fixedData";

export default function Page_Services_Combo_Details(props){

    const [ idPage, setIdPage ] = useState(GetListPag('currentPageId'));
    const [ dataPage, setDataPage ] = useState(GetDataPage('services_combo'));

    const [ title, setTitle ] = useState(InitialData('title'));
    const [ text, setText ] = useState(InitialData('text'));
    const [ services, setServices ] = useState(InitialData('services'));

    function InitialData(type){
        const newData = GetDataPage('services_combo').find(item => item.id == idPage);   
        if(newData){
            return newData[type];
        }
        if(type === 'services'){
            return [];
        }
        return '';
    }
    
    /* list_category */
    function ShowService(){
        const newData = [];        
        GetDataPage('services').map((elem, index)=>{
            if(newData.find(item => item.value == elem.id) || services.find(item => item.id_service['value'] == elem.id)){ }else {
                let nameService = "Tag: " + elem.tag + " - Nome: " + elem.name;
                newData.push({ "value": elem.id, "label": nameService });
            }
        });
        return newData;
    }
    function DeleteDataService(id, index, value){
        if(id === 0){
            const newData = [...services];
            newData.splice(index, 1);
            setServices(newData);
        }else {
            props.AltAccess("services", "delete_combo_service", "Deletar serviço", id, value, 0);
        }
    }
    function HandleDataService(type, index, value){
        const newData = [...services];
        newData[index][type] = value;
        setServices(newData);
    }
    /* end */

    function SaveData(event){
        event.preventDefault();
        props.setLoading(true);
        Reg_ServicesCombo(props.userId, idPage, title, text, services, props.CallbackSuccess, props.CallbackError);
    }

    useEffect(()=>{
        RegisterDataPage('services_combo', setDataPage);
        return ()=>{
            UnRegisterDataPage('services_combo', setDataPage);
        };
    }, []);

    useEffect(()=>{
        setIdPage(GetListPag('currentPageId'));

        setTitle(InitialData('title'));
        setText(InitialData('text'))
        setServices(InitialData('services'));
    }, [dataPage, idPage]);   

    return(
        <form className="page_content page_services_combo" onSubmit={ SaveData }>
            <Comp_Topic type={ true } icon="save" SearchInput="no_return" search="" title="Detalhes do combo" OpenPage={ props.OpenPage } page="services_combo" idPage={ 0 } qtdPage={ 0 } historic={ [] } />
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Dados do serviço</div>
                </div>
                <div className="list_input_data">
                    <Comp_Input className="" type="text" index="not" setValue={ setTitle } value={ title } input="" maxLength={ 140 } placeholder=" " required={ true } name="Título*" />
                </div>

                <div className="list_input_data">
                    <Comp_Textarea index="not" name="Observação" setValue={ setText } value={ text } />                  
                </div>
            </div>
            
            <div className="show_page_data">
                <div className="type_title">
                    <div className="">Lista dos serviços</div>
                    <div className="" onClick={ ()=>{ setServices([...services, { "id": 0, "id_service": { "value": 0, "label": "Selecione uma opção" } }]) } }>
                        Adicionar novo serviço
                    </div>
                </div>
                <div className="list_input_data">
                    {
                        services.length > 0 ?
                            services.map((elem, index)=>{
                                return(
                                    <div className="div_show_btn" key={ index }>
                                        <Comp_Select index={ index } name="" className="" opt={ ShowService() } setValue={ HandleDataService } value={ elem.id_service } input="id_service" />

                                        <div className="div_delete" onClick={ ()=>{ DeleteDataService(elem.id, index, elem.id_service['label']) } }>
                                            <Svg_Delete className="icons" color={ colorIcon }/>
                                        </div>
                                    </div>
                                )
                            })
                        : <div className="no_data">Nenhum serviço encontrado...</div>
                    }
                </div>
            </div>
        </form>
    )
}