import React, { useState, useEffect } from "react";

import './CheckedData.css';

import { RegisterModalData, RegisterModalObserver, SetModalState } from "interface/PopUp";

import { colorIconSecondary } from "fixedData";

import { Svg_ChekedReturn, Svg_Erro } from "services/SvgFile";

export default function PopUP_CheckedData(){

    const [ modalData, setModaldata ] = useState({});
    const [ showPopUp, setShowPopUp ] = useState(false);
    const [ list, setList ] = useState([]);

    function ClosePopUp(type){
        if(type === 0){
            SetModalState('CheckedData', false);
        }else if(type === 1){
            modalData.SaveData();
            SetModalState('CheckedData', false);
        }
    }

    function ShowDataReturn(type, data, index){
        switch (type) {
            case 'client':
                return(
                    <div className="div_inf_popup" key={ index }>
                        <div className="">
                            <b>Cliente:</b> { data.name }
                        </div>
                        <div className="">
                            <b>Razão social:</b> { data.corporate_name }
                        </div>
                        <div className="">
                            <b>Cliente desde:</b> { data.customer_since }
                        </div>
                    </div>
                )
            
            case 'services':
                return(
                    <div className="div_inf_popup" key={ index }>
                        <div className="">
                            <b>Tag:</b> { data.tag }
                        </div>
                        <div className="">
                            <b>Nome do serviço:</b> { data.name }
                        </div>
                    </div>
                )
            
            case 'portfolio':
                let status = 'Disponível';
                if(data.status === 0){
                    status = 'Indisponível';
                }
                return(
                    <div className="div_inf_popup" key={ index }>
                        <div className="">
                            <b>Título:</b> { data.title }
                        </div>
                        <div className="">
                            <b>Ano:</b> { data.year }
                        </div>
                        <div className="">
                            <b>status:</b> { status }
                        </div>
                    </div>
                )
        }
    }

    useEffect(()=>{
        RegisterModalData('CheckedData', setModaldata);
        RegisterModalObserver('CheckedData', setShowPopUp);
    }, []);  

    useEffect(()=>{
        if(showPopUp){
            setList(modalData.list);
        }
    }, [showPopUp]);

    return(
        (showPopUp ?
            <div className="PopUp">
                <div className="all CheckedData">
                    <div className="div_data">
                        <div className="content">
                            
                            <Svg_Erro color={ colorIconSecondary } className="icons_return" />
                            <div className="title_return">
                                <div className="">
                                    Já existe o seguinte resgitro com esse nome:
                                </div>
                                {
                                    list.length > 0 ?
                                        list.map((elem, index)=>{
                                            return ShowDataReturn(elem.type, elem, index)
                                        })
                                    : 
                                    <div className="">Nenhum dado encontrado</div>
                                }
                                <div className="">
                                    Mesmo assim, deseja realizar o cadastro?
                                </div>
                            </div>

                            <div className="content div_confirmation">
                                <div onClick={ ()=>{ ClosePopUp(1); } }>
                                    <div className="opt_select yes_update">Sim</div>
                                </div>
                                <div onClick={ ()=>{ ClosePopUp(0); } }>
                                    <div className="opt_select not_update">Não</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        : <React.Fragment></React.Fragment>)
    )
};
