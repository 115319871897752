import { useState, useEffect } from "react";

import './Details.css';

import ReactApexChart from "react-apexcharts";

import { GetDataPage } from "interface/Data";
import { SetModalData, SetModalState } from "interface/PopUp";

import { defaultColor, FormatPrice } from "fixedData";

import { Svg_ArrowRight } from "services/SvgFile";

export default function Page_Details(props){

    const date = new Date();
    const [ showYear, setShowYear ] = useState(date.getFullYear());
    const [ currentYear, setCurrentYear ] = useState(showYear);
    const [ listOptReport, setListOptReport ] = useState([
        { "name": "Novos clientes", "value": ShowValue('new_customers') },
        { "name": "Orçamentos enviados", "value": ShowValue('budgets_sent') },
        { "name": "Orçamentos perdidos", "value": ShowValue('lost_budgets') },
        { "name": "Orçamentos aprovados", "value": ShowValue('approved_budgets') },
        { "name": "Orçamentos em negociação", "value": ShowValue('budgets_under_negotiation') },
        { "name": "Valor total dos aprovados", "value": ShowValue('total_value_of_those_approved') },
        { "name": "Taxa de conversão de aprovados", "value": ShowValue('approved_conversion_rate') },
        { "name": "Clinte que mais aprovou orçamento", "value": ShowValue('clint_approved_budget') }
    ]);  
    
    /* annual goal */
    const [ nameAnnualGoal, setNameAnnualGoal ] = useState('Meta anual');
    const [ annualGoal, setAnnualGoal ] = useState(ShowAnnualGoal()); 
    const [ grafficAnnualGoal, setGrafficAnnualGoal ] = useState({
        chart: {
            type: 'line',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameAnnualGoal },
                    svg: { filename: nameAnnualGoal },
                    png: { filename: nameAnnualGoal }
                }
            },
        },
        plotOptions: {
            bar: { 
                columnWidth: '10%', 
                endingShape: 'rounded' 
            }
        },
        tooltip: {
            y: { 
                title: "",
                background: '#f4f4f4',
                color: '#000000'
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10, 
            style: {
                fontSize: '12px',
                colors: ["#000000"]
            },
            formatter: (value) => `${ FormatPrice('pt-BR', value) }`,
            background: { enabled: false }
        },
        stroke: { show: true },
        markers: {
            size: 6,
            strokeWidth: 1,
            radius: 2,
            shape: "circle"
        },
        title: {
            text: nameAnnualGoal,
            align: '',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        grid: {
            row: {
                colors: ['#f3f3f3', 'transparent'],
                opacity: 0.5
            },
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            },
            letterSpacing: '1px'
        },
        xaxis: {
            categories: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
        },
        yaxis: {
            labels: {
                formatter: (value) => `${ FormatPrice('pt-BR', value) }`
            },
            title: {
                text: 'Valor',
                style: {
                    fontFamily: 'bebas neue',
                    fontSize: '12px',
                    fontWeight: 'bold',
                    color: '#6c757d'
                }
            },
        }
    }); 
    function ShowAnnualGoal(){
        let data_annualGoal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        if(GetDataPage('settings')){            
            if(GetDataPage('settings')['annual_goal']){
                const listData = GetDataPage('settings')['annual_goal'].find(item => item.year == currentYear); 
                if(listData){                    
                    data_annualGoal[0] = FormatPrice('en-US', listData.january);
                    data_annualGoal[1] = FormatPrice('en-US', listData.february);
                    data_annualGoal[2] = FormatPrice('en-US', listData.march);
                    data_annualGoal[3] = FormatPrice('en-US', listData.april);
                    data_annualGoal[4] = FormatPrice('en-US', listData.may);
                    data_annualGoal[5] = FormatPrice('en-US', listData.june);
                    data_annualGoal[6] = FormatPrice('en-US', listData.july);
                    data_annualGoal[7] = FormatPrice('en-US', listData.august);
                    data_annualGoal[8] = FormatPrice('en-US', listData.september);
                    data_annualGoal[9] = FormatPrice('en-US', listData.october);
                    data_annualGoal[10] = FormatPrice('en-US', listData.november);
                    data_annualGoal[11] = FormatPrice('en-US', listData.december);                
                }
            }
        }
        
        let data_goalAchieved = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        // const listDataExit = GetDataPage('balance')['exit'].find(item => item.year == currentYear); 
        // if(listDataExit){
        //     listDataExit['month'].map((elem, index)=>{                
        //         switch (elem.month) {
        //             case '01': case 1: data_entry[0] = elem.cash; break;
        //             case '02': case 2: data_entry[1] = elem.cash; break;
        //             case '03': case 3: data_entry[2] = elem.cash; break;
        //             case '04': case 4: data_entry[3] = elem.cash; break;
        //             case '05': case 5: data_entry[4] = elem.cash; break;
        //             case '06': case 6: data_entry[5] = elem.cash; break;
        //             case '07': case 7: data_entry[6] = elem.cash; break;
        //             case '08': case 8: data_entry[7] = elem.cash; break;
        //             case '09': case 9: data_entry[8] = elem.cash; break;
        //             case '10': case 10: data_entry[9] = elem.cash; break;
        //             case '11': case 11: data_entry[10] = elem.cash; break;
        //             case '12': case 12: data_entry[11] = elem.cash; break;
        //         }
        //     });
        // }

        return [
            {
                name: "Meta",
                data: data_annualGoal
            },
            {
                name: "Valor atingido do mês",
                data: data_goalAchieved
            }
        ];
    }
    function TargetValueAchieved(type){
        let price = 0.00;
        return FormatPrice('pt-BR', price);
    }
    /* end */

    /* motive */
    const [ nameMotive, setNameMotive ] = useState('Motivos de cancelamento');
    const [ listMotive, setListMotive ] = useState(ShowListMotive());
    const [ motive, setMotive ] = useState(ShowCurrentYearMotive());
    const [ grafficMotive, setGrafficMotive ] = useState({
        chart: {
            type: 'pie',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameMotive },
                    svg: { filename: nameMotive },
                    png: { filename: nameMotive }
                }
            },
        },
        series: listMotive,
        labels: motive,
        tooltip: {
            y: { 
                formatter: (value) => `${ Math.round(value) }`,
                background: '#f4f4f4',
                color: '#000000'
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10, 
            style: {
                fontSize: '12px',
                colors: ["#ffffff"]
            },
            formatter: (value) => `${ FormatPrice('pt-BR', value) }`,
            background: { enabled: false }
        },
        title: {
            text: nameMotive,
            align: 'center',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            },
            letterSpacing: '1px'
        }
    });    
    function ShowListMotive(){
        const newData = [2, 4, 6, 1, 5];
        return newData;
    }
    function ShowCurrentYearMotive(){
        const newData = ['Teste 1', 'Teste 2', 'Teste 3', 'Teste 4', 'Teste 5'];
        return newData;
    }
    /* end */

    /* category */
    const [ nameCategory, setNameCategory ] = useState('Categorias mais utilizadas');
    const [ listCategory, setListCategory ] = useState(ShowListMotive());
    const [ category, setCategory ] = useState(ShowCurrentYearMotive());
    const [ grafficCategory, setGrafficCategory ] = useState({
        chart: {
            type: 'pie',
            height: 350,
            zoom: { enabled: false },
            toolbar: {
                show: true,
                tools: { download: true },
                export: {
                    csv: { filename: nameCategory },
                    svg: { filename: nameCategory },
                    png: { filename: nameCategory }
                }
            },
        },
        series: listCategory,
        labels: category,
        tooltip: {
            y: { 
                formatter: (value) => `${ Math.round(value) }`,
                background: '#f4f4f4',
                color: '#000000'
            }
        },
        dataLabels: {
            enabled: true,
            offsetY: -10, 
            style: {
                fontSize: '12px',
                colors: ["#ffffff"]
            },
            formatter: (value) => `${ FormatPrice('pt-BR', value) }`,
            background: { enabled: false }
        },
        title: {
            text: nameCategory,
            align: 'center',
            style: {
                fontFamily: 'bebas neue',
                fontSize: '26px',
                fontWeight: '500',
                color: '#6c757d',
                lineHeight: 'normal'
            }
        },
        legend: {
            show: true,
            showForSingleSeries: true,
            position: 'bottom',
            horizontalAlign: 'center',
            fontSize: '11px',
            fontFamily: 'Poppins',
            labels: {
                colors: '#333'
            },
            letterSpacing: '1px'
        }
    });    
    function ShowListMotive(){
        const newData = [2, 4, 6, 1, 5];
        return newData;
    }
    function ShowCurrentYearMotive(){
        const newData = ['Teste 1', 'Teste 2', 'Teste 3', 'Teste 4', 'Teste 5'];
        return newData;
    }
    /* end */

    function ShowValue(type){
        const newData = [];
        switch (type) {
            case 'budgets_sent':
                return newData.length;
                
            case 'lost_budgets':
                return newData.length;

            case 'approved_budgets':
                return newData.length;

            case 'budgets_under_negotiation':
                return newData.length;

            case 'total_value_of_those_approved':
                return '0,00';

            case 'approved_conversion_rate':
                return '0%';

            case 'new_customers':
                return GetDataPage('client').filter(item => item.customer_since == currentYear).length;

            case 'clint_approved_budget':
                return 'Nome cliente que mais fechou orçamento';
        }
    }

    useEffect(()=>{
        setListOptReport([
            { "name": "Novos clientes", "value": ShowValue('new_customers') },
            { "name": "Orçamentos enviados", "value": ShowValue('budgets_sent') },
            { "name": "Orçamentos perdidos", "value": ShowValue('lost_budgets') },
            { "name": "Orçamentos aprovados", "value": ShowValue('approved_budgets') },
            { "name": "Orçamentos em negociação", "value": ShowValue('budgets_under_negotiation') },
            { "name": "Valor total dos aprovados", "value": ShowValue('total_value_of_those_approved') },
            { "name": "Taxa de conversão de aprovados", "value": ShowValue('approved_conversion_rate') },
            { "name": "Clinte que mais aprovou orçamento", "value": ShowValue('clint_approved_budget') }
        ]);

        setAnnualGoal(ShowAnnualGoal());
        TargetValueAchieved('achieved_value');
        TargetValueAchieved('percentage');

        setListMotive(ShowListMotive());
        setMotive(ShowCurrentYearMotive());
        
        setListCategory(ShowListMotive());
        setCategory(ShowCurrentYearMotive());
    }, [currentYear]);

    return(
        <div className="page_content div_inf_page inf_dashboard">
            <div className="div_show_year">
                <div className="arrow" onClick={ ()=>{ setCurrentYear((currentYear - 1)); } }>
                    <Svg_ArrowRight className="icons left" color={ defaultColor } />
                </div>

                <div className="show_year">
                    { currentYear }
                </div>

                <div className="arrow" onClick={ ()=>{ setCurrentYear((currentYear + 1)); } }>
                    <Svg_ArrowRight className="icons" color={ defaultColor } />
                </div>
            </div>

            <div className="list_data">
                {
                    listOptReport.map((elem, index)=>{
                        return(
                            <div className="div_inf_data" key={ index } style={ { borderColor: defaultColor } }>
                                <div className={ elem.name === 'Clinte que mais aprovou orçamento' ? "qtd show_name" : "qtd" }>{ elem.value }</div>
                                <div className="title" style={ { backgroundColor: defaultColor } }>{ elem.name }</div>
                            </div>
                        )
                    })
                }
            </div>

            <div className="list_opt_grafic">
                <div className="title_block">Controle de metas</div>
                <div className="list_data">
                    <div className="div_inf_data" onClick={ ()=>{ }}>
                        <div className="qtd">{ TargetValueAchieved('achieved_value') }</div>
                        <div className="title">Valor atingido</div>
                    </div>
                    
                    <div className="div_inf_data" onClick={ ()=>{ }}>
                        <div className="qtd">{ TargetValueAchieved('percentage') }</div>
                        <div className="title">Percentual de metas atingida</div>
                    </div>
                </div>
                    
                <div className="div_graffic">
                    <ReactApexChart options={ grafficAnnualGoal } series={ annualGoal } type="line" height={ 400 } />
                </div>
            </div>
            
            <div className="list_graffic_pizza">
                <div className="graffic_pizza">
                    <ReactApexChart options={ grafficMotive } series={ listMotive } type="pie" height={ 350 } />
                </div>
                <div className="graffic_pizza">
                    <ReactApexChart options={ grafficCategory } series={ listCategory } type="pie" height={ 350 } />
                </div>
            </div>
        </div>
    )
}