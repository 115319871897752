const ModalState = {
    Tag: false,
    Color: false,
    Schedule: false,
    ShowFile: false,
    Category: false,
    InfGraffic: false,
    CheckedData: false,
    Confirmation: false,
    RecoverEmail: false,
    ReturnResponse: false
};

const ModalData = {
    Tag: {},
    Color: {},
    ShowFile: {},
    Schedule: {},
    Category: {},
    InfGraffic: {},
    CheckedData: {},
    Confirmation: {},
    RecoverEmail: {},
    ReturnResponse: {}
}

const NotifyModalObserver = {
    Tag: [],
    Color: [],
    ShowFile: [],
    Schedule: [],
    Category: [],
    InfGraffic: [],
    CheckedData: [],
    Confirmation: [],
    RecoverEmail: [],
    ReturnResponse: []
}

const NotifyModalData = {
    Tag: [],
    Color: [],
    ShowFile: [],
    Schedule: [],
    Category: [],
    InfGraffic: [],
    CheckedData: [],
    Confirmation: [],
    RecoverEmail: [],
    ReturnResponse: []
}

// ModalData
export function SetModalData(modal, data){
    ModalData[modal] = data;
    NotifyModalData[modal].forEach(element => {
        element(data);
    });
}
export function GetModalData(key, value){
    return ModalData[key][value];
}
export function RegisterModalData(modal, func){
    NotifyModalData[modal].push(func);
}

// ModalState
export function SetModalState(modal, state){
    ModalState[modal] = state;
    NotifyModalObserver[modal].forEach(element => {
        element(state);
    });
}
export function GetModalState(key, value){
    return ModalState[key][value];
}
export function RegisterModalObserver(modal, func){
    NotifyModalObserver[modal].push(func);
}
